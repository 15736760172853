<template>
  <section class="section">
    <form @submit.prevent="handleSubmit">
      <b-field label="Email">
        <b-input v-model="email" type="email" />
      </b-field>

      <b-field label="Password">
        <b-input v-model="password" type="password" password-reveal />
      </b-field>

      <div class="field is-center">
        <div class="control">
          <b-button
            :loading="loading"
            :disabled="isSubmitDisabled"
            class="button is-primary"
            @click.prevent="handleSubmit"
          >
            Вход
          </b-button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { AppRoute } from "@/common/const/route";

export default {
  name: "Login",

  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },

  computed: {
    isSubmitDisabled() {
      return !(!!this.email.trim().length && !!this.password.trim().length);
    },
  },

  methods: {
    ...mapActions({
      login: "auth/login",
    }),

    async handleSubmit() {
      this.loading = true;
      const isAuth = await this.login({
        identifier: this.email,
        password: this.password,
      });

      this.loading = false;
      if (!isAuth) {
        return;
      }

      await this.$router.push(AppRoute.MAIN);
    },
  },
};
</script>
